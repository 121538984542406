import React, { useEffect } from "react";
import { env } from "../config";
import { useNavigate } from "react-router";

function PurchaseModal(props: any) {
  const navigate = useNavigate();
  const {
    sethandlePurchaseModal,
    handlePurchaseModal,
    currentProduct,
    checkoutDetails,
  } = props;

  useEffect(() => {
    console.log(
      "ProductDataProductDataProductDataProductData",
      checkoutDetails
    );
  }, [currentProduct]);

  return (
    <div className="bg-transparent duration-700 backdrop-brightness-50  backdrop-blur-sm flex justify-end items-end  md:justify-center md:items-center w-screen h-[100dvh] fixed top-0 left-0 z-[999999] ">
      <div className="bg-white lg:max-w-screen-sm h-auto p-5 md:p-10 flex flex-col items-center relative shadow-md rounded-md">
        <button
          onClick={() => navigate("/merchandize")}
          type="button"
          className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="successModal"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className="flex flex-col items-center justify-center gap-6 mb-6">
          <div className="w-auto h-10 rounded-full bg-green-100  p-2 flex items-center justify-center">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Success</span>
          </div>
          <p className="font-medium text-xl text-black-75 ">
            {" "}
            Thank you for your payment!
          </p>

          <div className="mt-4">
            <p className="text-gray-700">
              We’ve received your payment successfully. A confirmation email
              with your transaction details has been sent to your inbox.
            </p>
            <p className="mt-4 text-gray-700">
              Please check your email (and spam/junk folder) for the details. If
              you don’t see the email within a few minutes, feel free to contact
              us at
              <a
                href="mailto:support@example.com"
                className="text-blue-500 hover:underline"
              >
                support@example.com
              </a>
              .
            </p>
            <p className="mt-4 text-gray-700">
              One of our team members will also reach out to you via email
              shortly to assist further.
            </p>
          </div>
        </div>

        <div className="bg-yellow-200"></div>
        {/* <div className="flex h-16 bg-pink-400">
                    <img className="" src={`${env}${currentProduct?.attributes?.image?.data[0].attributes.url}`} alt={currentProduct?.attributes?.title} />
                    <div className="">
                        <p className="font-bold font-custom">{currentProduct?.attributes?.title}</p>
                        <p className="text-sm">{currentProduct?.attributes?.Cardtitle}</p>
                    </div>
                </div> */}
        <button
          onClick={() => navigate("/merchandize")}
          className="bg-[#616161] text-white border border-white hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] rounded-[50px] px-4 py-2 my-3"
        >
          More Merch
        </button>
      </div>
    </div>
  );
}

export default PurchaseModal;
