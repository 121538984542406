import React, { useEffect, useRef, useState } from "react";
import { CartIcon, Product, ProductWithQuantity } from "../Pages/Merchandize";
import { useDispatch, useSelector } from "react-redux";
import { env } from "../config";
import { ButtonLoader } from "../Pages/MerchandizeIndividualPage";
import * as Actions from "../redux/actions";
import toast from "react-hot-toast";
import { useLocation } from "react-router";

interface Props {}
const Checkout: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cartData, ProductData } = useSelector((state: any) => ({
    cartData: state?.Page?.Cart,
    ProductData: state?.Page?.ProductData,
  }));
  const region =
    Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone?.includes("Asia/Kolkata") ||
    Intl.DateTimeFormat().resolvedOptions().timeZone?.includes("Asia/Calcutta")
      ? "ind"
      : "usd";

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      // dropdownRef.current.style.transform = `translateX(-50%) translateY(100%)`;
      // setTimeout(() => {
      setIsOpen(false);
      // }, 250);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const productsInCart = ProductData?.filter((product: Product) =>
    cartData?.some(
      (item: { price: string }) =>
        item.price ===
        (region === "ind" ? product?.prices?.ind : product?.prices?.usd)
    )
  )?.map((product: Product) => {
    const cartItem = cartData?.find(
      (item: { price: string }) =>
        item.price ===
        (region === "ind" ? product?.prices?.ind : product?.prices?.usd)
    );

    return {
      ...product, // Include all product data
      quantity: cartItem?.quantity || 0, // Attach the quantity from the cart
    };
  });

  const [buyNowLoader, setBuyNowLoader] = useState(false);
  const checkout = async (item?: { price: string; quantity: number }) => {
    setBuyNowLoader(true);
    const cart = cartData?.map(
      (item: { price: string; quantity: number; name: string }) => {
        return { price: item?.price, quantity: item?.quantity };
      }
    );

    try {
      const response = await fetch(`${env}/api/checkout/session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cart }),
      });
      const { url } = await response.json();
      setBuyNowLoader(false);
      console.log("urllllll", url);
      if (url) {
        window.location.href = url; //   Redirect to Stripe Checkout
      } else {
        toast?.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };
  console.log("prouctscads", productsInCart);

  return (
    <button
      className={` ${
        cartData?.length ? "visible" : "hidden"
      } p-3 w-max bg-orange-500 rounded-full fixed top-24 left-10 md:top-24 md:left-24 z-[9999]`}
      onClick={() => setIsOpen(true)}
    >
      <div className="text-xs absolute right-0 shadow-md top-0 w-3 h-3  p-2 flex items-center justify-center font-semibold rounded-full bg-white text-orange-500 ">
        {cartData?.length}
      </div>
      <CartIcon styles="fill-white" />
      {isOpen ? (
        <>
          <div
            className={
              "fixed inset-0  bg-transparent duration-700 backdrop-brightness-50  backdrop-blur-sm  bg-opacity-50 z-[998]"
            }
          ></div>

          <div
            ref={dropdownRef}
            className={`fixed slidee w-screen md:max-w-lg  h-max bottom-0 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 rounded-t-2xl  md:rounded-xl  p-5 pt-3 md:pt-5  shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] flex flex-col items-center justify-center duration-200 z-[999] bg-white`}
          >
            <p className="text-xl w-full sm:w-9/12 md:w-full justify-between   font-medium p-3 pb-3 flex items-center md:mr-auto gap-4 text-black-75">
              Your Cart
              <div ref={dropdownRef}>
                <button
                  onClick={(e) => {
                    setIsOpen(false);
                    e?.stopPropagation();
                    e?.preventDefault();
                    console.log("openornot", isOpen);
                  }}
                >
                  <svg
                    className="w-6 h-6 "
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.877075 7.49988C0.877075 3.84219 3.84222 0.877045 7.49991 0.877045C11.1576 0.877045 14.1227 3.84219 14.1227 7.49988C14.1227 11.1575 11.1576 14.1227 7.49991 14.1227C3.84222 14.1227 0.877075 11.1575 0.877075 7.49988ZM7.49991 1.82704C4.36689 1.82704 1.82708 4.36686 1.82708 7.49988C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C10.6329 13.1727 13.1727 10.6329 13.1727 7.49988C13.1727 4.36686 10.6329 1.82704 7.49991 1.82704ZM9.85358 5.14644C10.0488 5.3417 10.0488 5.65829 9.85358 5.85355L8.20713 7.49999L9.85358 9.14644C10.0488 9.3417 10.0488 9.65829 9.85358 9.85355C9.65832 10.0488 9.34173 10.0488 9.14647 9.85355L7.50002 8.2071L5.85358 9.85355C5.65832 10.0488 5.34173 10.0488 5.14647 9.85355C4.95121 9.65829 4.95121 9.3417 5.14647 9.14644L6.79292 7.49999L5.14647 5.85355C4.95121 5.65829 4.95121 5.3417 5.14647 5.14644C5.34173 4.95118 5.65832 4.95118 5.85358 5.14644L7.50002 6.79289L9.14647 5.14644C9.34173 4.95118 9.65832 4.95118 9.85358 5.14644Z"
                      fill="currentColor"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </p>

            <div className="w-full sm:w-9/12 md:w-full   max-h-96 overflow-y-scroll grid gap-5 ">
              {productsInCart?.map((item: ProductWithQuantity) => {
                console.log("produyctss", item);
                return <CheckoutCard item={item} />;
              })}
            </div>

            <div className="w-full sm:w-9/12 md:w-full mt-5">
              {" "}
              <button
                onClick={() => {
                  checkout(cartData);
                }}
                className=" w-max ml-auto px-4 mb-3 lg:mb-0 py-2 justify-center flex items-center text-base gap-3 text-white  hover:text-orange-500  border-white hover:bg-white border hover:border-orange-500 bg-orange-500 rounded-[50px] "
              >
                Checkout
                {buyNowLoader ? <ButtonLoader /> : null}
              </button>
            </div>
          </div>
        </>
      ) : null}
    </button>
  );
};

export default Checkout;

interface CheckoutCardProps {
  item: ProductWithQuantity;
}
const CheckoutCard: React.FC<CheckoutCardProps> = ({ item }) => {
  const dispatch = useDispatch();
  const region =
    Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone?.includes("Asia/Kolkata") ||
    Intl.DateTimeFormat().resolvedOptions().timeZone?.includes("Asia/Calcutta")
      ? "ind"
      : "usd";

  const handleRemove = (product: ProductWithQuantity) => {
    dispatch(
      Actions?.CartAction({
        price: region === "ind" ? product?.prices?.ind : product?.prices?.usd,

        quantity: 0,
        name: product?.title,
      })
    );
  };

  const reduceItem = (
    action: "add" | "subtract",
    product: ProductWithQuantity,
    count: number
  ) => {
    if (count === product?.stock && action === "add") {
      // toast.error(`You can only add up to ${} of each item.`);

      return;
    }

    if (action === "add") {
      dispatch(
        Actions?.CartAction({
          price: region === "ind" ? product?.prices?.ind : product?.prices?.usd,

          quantity: count + 1,
          name: product?.title,
        })
      );
    } else {
      dispatch(
        Actions?.CartAction({
          price: region === "ind" ? product?.prices?.ind : product?.prices?.usd,
          quantity: count - 1,
          name: product?.title,
        })
      );
    }
  };

  return (
    <div className="flex items-start w-full border-t gap-3  text-black-75 font-sans  p-3 rounded-md text-left">
      <div className="rounded-md ">
        <img
          className=" h-10 w-10  duration-100  object-scale-down   mx-auto"
          src={`${env}${item?.image[0]?.url}`}
          alt={item?.title}
        />
      </div>
      <div className="w-full flex flex-col justify-start gap-1">
        <div className="flex items-center justify-between w-full">
          <p className="text-base font-medium">{item.title}</p>
          <p className="text-sm font-medium whitespace-nowrap">
            <span className=" text-sm  font-light">₹</span>{" "}
            {Number(
              region === "ind"
                ? item?.price?.split("#")[0]
                : item?.price?.split("#")[1]
            ) * item?.quantity}
          </p>
        </div>
        <p className="text-sm font-normal text-gray-500">{item.Cardtitle}</p>
        <div className="flex items-center justify-between w-full"></div>
        <div className="flex items-center gap-3 justify-between mt-4 ">
          <div className="grid border-gray-400 border  rounded-md grid-cols-3 w-max">
            <button
              onClick={() => {
                reduceItem("subtract", item, item?.quantity);
              }}
              className="p-1   "
            >
              <svg
                className=" w-4 h-4 stroke-2"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z"
                  fill="currentColor"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <p className="text-sm font-medium p-1 flex justify-center items-center">
              {item?.quantity}
            </p>
            <button
              onClick={() => {
                reduceItem("add", item, item?.quantity);
              }}
              className="p-1 "
            >
              <svg
                className=" w-4 h-4 stroke-2"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z"
                  fill="currentColor"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <button
            onClick={() => handleRemove(item)}
            className="flex items-center gap-2 p-1 text-sm text-gray-500 rounded-md border"
          >
            <svg
              className="w-4 h-4  stroke-gray-500"
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 5H18M9 5V5C10.5769 3.16026 13.4231 3.16026 15 5V5M9 20H15C16.1046 20 17 19.1046 17 18V9C17 8.44772 16.5523 8 16 8H8C7.44772 8 7 8.44772 7 9V18C7 19.1046 7.89543 20 9 20Z"
                stroke="#6b7280"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};
