import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { env } from "../config";
import * as Actions from "../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import PurchaseModal from "../components/PurchaseModal";
import useWindowDimensions from "../components/useWindowDimensions";
import Loader from "../components/Loader";
import { CartIcon, handleStockLabel, Product } from "./Merchandize";
import toast from "react-hot-toast";
import Checkout from "../components/Checkout";

function MerchandizeIndividualPage() {
  let { key } = useParams();
  let { checkoutSessionId } = useParams();
  console.log("keyy", key);

  const ProductData: any = useSelector(
    (state: any) => state?.Page?.ProductData
  );

  const cartData: any = useSelector((state: any) => state?.Page?.Cart);

  console.log("cartData", cartData);
  const [currentProduct, setCurrentProduct] = useState<Product | null>(null);

  const [handlePurchaseModal, sethandlePurchaseModal] = useState(false);
  const [loader, setloader] = useState("notloading");
  const [checkoutDetails, setCheckoutDetails] = useState("");
  const dispatch = useDispatch();

  const getProductData = () => {
    axios.get(`${env}/api/products?populate=deep`).then(function (response) {
      dispatch(Actions.ProductDataAction(response?.data?.data));
    });
  };
  console.log("producrData", ProductData);
  useEffect(() => {
    if (ProductData.length === 0) {
      getProductData();
      window.scrollTo({
        top: 0,
        left: 1,
        behavior: "smooth",
      });
    }
    let currentData = ProductData.filter((node: Product) => {
      return node?.title?.trim().toLowerCase()?.replaceAll(" ", "_") === key;
    })[0];
    setCurrentProduct(currentData);
  }, [ProductData, key]);

  useEffect(() => {
    if (checkoutSessionId) {
      console.log("checkoutSessionId", checkoutSessionId, key);
      setloader("loading");
      axios
        .get(`${env}/api/stripeapis/${checkoutSessionId}`)
        .then(function (response) {
          console.log("please work", response);
          setCheckoutDetails(response?.data?.object?.object);
          sethandlePurchaseModal(!handlePurchaseModal);
          setloader("notloading");
        })
        .catch((error: any) => {
          console.log("error", error);
          setloader("notloading");
        });
    }
  }, [checkoutSessionId]);

  return loader === "loading" || !currentProduct ? (
    <Loader />
  ) : (
    <div className="px-5 py-7 relative lg:px-10 xl:px-20 lg:py-14 overflow-x-hidden">
      <Checkout />
      <MerchDetails
        key={currentProduct?.title}
        currentProduct={currentProduct}
        merch={currentProduct}
      />
      <div className="text-2xl md:text-4xl lg:text-5xl px-5 py-7 lg:px-10 xl:px-20 lg:py-14 rounded-md bg-[#e9e9e9] font-bold flex items-center font-custom text-black-75 justify-center my-10">
        You May Also Like
      </div>
      <MerchSlider products={ProductData} />
      {handlePurchaseModal && (
        <PurchaseModal
          currentProduct={currentProduct}
          checkoutDetails={checkoutDetails}
          sethandlePurchaseModal={sethandlePurchaseModal}
          handlePurchaseModal={handlePurchaseModal}
        />
      )}
    </div>
  );
}

export default MerchandizeIndividualPage;

export const ButtonLoader = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="w-6 h-6 text-gray-700 animate-spinning dark:text-orange-500 fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

function MerchDetails({
  merch,
  currentProduct,
}: {
  merch: Product;
  currentProduct: Product | null;
}) {
  const ProductData: any = useSelector(
    (state: any) => state?.Page?.ProductData
  );
  const dispatch = useDispatch();
  const cartData = useSelector((state: any) => state?.Page?.Cart);
  const region =
    Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone?.includes("Asia/Kolkata") ||
    Intl.DateTimeFormat().resolvedOptions()?.timeZone?.includes("Asia/Calcutta")
      ? "ind"
      : "usd";

  const [buyNowLoader, setBuyNowLoader] = useState(false);

  const [currentImage, setcurrentImage] = useState(0);

  const handleImageChange = (currentIndex: number) => {
    if (
      currentProduct?.image?.length &&
      currentIndex === currentProduct?.image?.length - 1
    ) {
      setcurrentImage(0);
    } else {
      setcurrentImage((old) => old + 1);
    }
  };

  const checkout = async (item?: { price: string; quantity: number }) => {
    setBuyNowLoader(true);
    const cart = item
      ? [item]
      : cartData?.map(
          (item: { price: string; quantity: number; name: string }) => {
            return { price: item?.price, quantity: item?.quantity };
          }
        );
    try {
      const response = await fetch(`${env}/api/checkout/session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cart }),
      });
      const { url } = await response.json();
      setBuyNowLoader(false);
      if (url) {
        window.location.href = url; //   Redirect to Stripe Checkout
      } else {
        toast?.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  const attributes = [
    { label: "Material", value: merch?.material },
    { label: "Pattern", value: merch?.pattern },
    { label: "Dimension", value: merch?.dimension },
    { label: "Color", value: merch?.color },
  ];

  const addToCart = (item: {
    price: string;
    quantity: number;
    name: string;
  }) => {
    dispatch(Actions?.CartAction(item));
    toast?.success(`${item?.name} has been added to your cart.`);
  };

  const productsInCart = ProductData?.filter((product: Product) =>
    cartData?.some(
      (item: { price: string }) =>
        item.price ===
        (region === "ind" ? product?.prices?.ind : product?.prices?.usd)
    )
  )
    ?.map((product: Product) => {
      const cartItem = cartData?.find(
        (item: { price: string }) =>
          item.price ===
          (region === "ind" ? product?.prices?.ind : product?.prices?.usd)
      );

      return {
        ...product, // Include all product data
        quantity: cartItem?.quantity || 0, // Attach the quantity from the cart
      };
    })
    ?.filter(
      (node: any) =>
        node?.price ===
        (region === "ind" ? merch?.prices?.ind : merch?.prices?.usd)
    );

  const reduceItem = (
    action: "add" | "subtract",
    product: Product,
    count: number
  ) => {
    if (count === product?.stock && action === "add") {
      // toast.error(`You can only add up to ${} of each item.`);

      return;
    }

    if (action === "add") {
      dispatch(
        Actions?.CartAction({
          price: region === "ind" ? product?.prices?.ind : product?.prices?.usd,
          quantity: count + 1,
          name: product?.title,
        })
      );
    } else {
      dispatch(
        Actions?.CartAction({
          price: region === "ind" ? product?.prices?.ind : product?.prices?.usd,
          quantity: count - 1,
          name: product?.title,
        })
      );
    }
  };
  const countOfCurrentItem =
    cartData?.filter(
      (item: { price: string; quantity: number }) =>
        item?.price ===
        (region === "ind" ? merch?.prices?.ind : merch?.prices?.usd)
    )[0]?.quantity ?? 1;
  return (
    <div className="grid lg:grid-cols-2 ">
      <div className=" relative flex justify-center items-center mb-4 lg:mb-0 ">
        {merch?.image?.length > 1 && (
          <>
            <svg
              onClick={() => {
                handleImageChange(currentImage);
              }}
              className="absolute cursor-pointer  top-1/2 right-0 sm:right-10 -translate-y-1/2 "
              width="40"
              height="40"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
                fill="currentColor"
                fill-rule="evenodd"
                clip-rule="evenodd"
              ></path>
            </svg>
            <svg
              onClick={() => {
                handleImageChange(currentImage);
              }}
              className="absolute cursor-pointer top-1/2 sm:-1/2 left-0 sm:left-10 rotate-180 -translate-y-1/2 "
              width="40"
              height="40"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
                fill="currentColor"
                fill-rule="evenodd"
                clip-rule="evenodd"
              ></path>
            </svg>
          </>
        )}

        <img
          className="w-full h-auto max-w-[450px] max-h-[450px] object-scale-down duration-300"
          src={`${env}${merch?.image[currentImage]?.url}`}
          alt={merch?.title}
        />
      </div>
      <div className="xl:p-7 font-custom text-black-75 flex flex-col ">
        <div className="font-semibold lg:text-[44px] md:text-3xl text-2xl ">
          {merch?.title}
        </div>
        <div className=" font-semibold flex items-start lg:text-[44px] md:text-3xl text-2xl my-2 md:my-7">
          {region === "ind" ? (
            <>
              {" "}
              <span className=" text-base  font-light">₹</span>
              {merch?.price?.split("#")[0]}
            </>
          ) : (
            <>
              <span className=" text-base  font-light">$</span>
              {merch?.price?.split("#")[1]}
            </>
          )}
        </div>
        <div className="md:text-base text-base lg:text-lg">
          {merch?.Cardtitle}
        </div>
        <div className=" w-full flex flex-col my-4">
          {attributes.map(
            (attribute, index) =>
              attribute.value && (
                <div
                  key={index}
                  className="flex flex-col border-t py-3 lg:w-[70%]"
                >
                  <span className="font-bold inline-block">
                    {attribute.label}:
                  </span>
                  <span className="inline-block pt-3">{attribute.value}</span>
                </div>
              )
          )}

          {merch?.titleValue?.map((node: any, index: number) => {
            return (
              <div
                className={`flex flex-col  ${
                  merch?.titleValue.length - 1 === index
                    ? " border-b"
                    : "border-t border-b"
                } py-3 lg:w-[70%]`}
              >
                <span className="font-bold  capitalize inline-block">
                  {node?.title} :
                </span>
                <span className=" inline-block pt-3">{node?.value}</span>
              </div>
            );
          })}
          {(merch?.stock || merch?.stock === 0) && (
            <div className="flex flex-col  border-t py-3 lg:w-[70%]">
              <span className="font-bold inline-block ">Stock:</span>
              <span
                style={{
                  color: handleStockLabel(merch?.stock)?.color,
                }}
                className=" inline-block pt-3"
              >
                {handleStockLabel(merch?.stock)?.text}{" "}
              </span>
            </div>
          )}
        </div>
        {merch?.stock === 0 ? (
          <div
            className="w-full md:w-max flex flex-col flex-wrap p-4 bg-gray-100 text-gray-800 border border-gray-300 rounded-md my-4"
            role="alert"
          >
            <span className="font-bold">Sold Out !</span>
            <p>This item is no longer available. Please check back later.</p>
          </div>
        ) : (
          <>
            <div className="grid border rounded-md grid-cols-3 w-max">
              <button
                onClick={() => {
                  reduceItem("subtract", merch, countOfCurrentItem);
                }}
                className="p-1"
              >
                <svg
                  className=""
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              <p className="text-sm font-medium p-1 flex justify-center items-center">
                {countOfCurrentItem}
              </p>
              <button
                onClick={() => {
                  reduceItem("add", merch, countOfCurrentItem);
                }}
                className="p-1"
              >
                <svg
                  className=""
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="w-full flex gap-3 items-center mt-5 ">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  addToCart({
                    price:
                      region === "ind"
                        ? merch?.prices?.ind
                        : merch?.prices?.usd,
                    quantity: countOfCurrentItem,
                    name: merch?.title,
                  });
                }}
                className={`px-4 h-max flex items-center gap-2 hover:scale-[1.04] duration-75 group rounded-full hover:bg-orange-500 hover:text-white text-orange-500 border py-2 font-medium border-orange-500`}
              >
                <CartIcon
                  styles={
                    merch?.stock === 0
                      ? "fill-orange-300"
                      : "fill-orange-500 group-hover:fill-white `"
                  }
                />{" "}
                Add to cart
              </button>
              <button
                onClick={() => {
                  checkout({
                    price:
                      region === "ind"
                        ? merch?.prices?.ind
                        : merch?.prices?.usd,
                    quantity: countOfCurrentItem,
                  });
                }}
                className=" w-max px-4 py-2 justify-center flex items-center text-base gap-3 text-white  hover:text-orange-500  border-white hover:bg-white border hover:border-orange-500 bg-orange-500   rounded-[50px]   my-3"
              >
                Buy Now
                {buyNowLoader ? <ButtonLoader /> : null}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function MerchSlider({ products }: { products: Product[] }) {
  const { width, height } = useWindowDimensions();
  console.log("widthheight", width, height);

  const [currentSlide, setcurrentSlide] = useState({ start: 0, end: 3 });
  const changeSlide = (direction: string, x: number) => {
    let copyCurrentSlide = { ...currentSlide };

    if (direction === "forwards") {
      if (copyCurrentSlide?.end !== products?.length - 1) {
        copyCurrentSlide.end = copyCurrentSlide.end + 1;
        copyCurrentSlide.start = copyCurrentSlide.start + 1;
      } else {
        copyCurrentSlide.end =
          width > 1280
            ? 3
            : width >= 1024 && width <= 1280
            ? 2
            : width <= 768 && width >= 640
            ? 1
            : 0;
        copyCurrentSlide.start = 0;
      }

      setcurrentSlide(copyCurrentSlide);
    } else {
      if (copyCurrentSlide.start !== 0) {
        copyCurrentSlide.end = copyCurrentSlide.end - 1;
        copyCurrentSlide.start = copyCurrentSlide.start - 1;
      } else if (copyCurrentSlide.end === 0 && copyCurrentSlide.start === 0) {
        copyCurrentSlide.end = products.length - 1;
        copyCurrentSlide.start = products.length - 1;
      } else {
        copyCurrentSlide.end = width <= 640 ? 0 : products?.length - 1;
        copyCurrentSlide.start =
          width > 1280
            ? products?.length - 1 - 3
            : width >= 1024 && width <= 1280
            ? products?.length - 1 - 2
            : width <= 768 && width >= 640
            ? products?.length - 1 - 1
            : 0;
      }

      setcurrentSlide(copyCurrentSlide);
    }
  };

  const changeSlideLengthWithWidth = () => {
    let copyCurrentSlide = { ...currentSlide };
    if (width !== null) {
      if (width >= 1024 && width <= 1280) {
        copyCurrentSlide = { ...copyCurrentSlide, end: 2 };
      } else if (width > 1280) {
        copyCurrentSlide = { ...copyCurrentSlide, end: 3 };
      } else if (width <= 768 && width >= 640) {
        copyCurrentSlide = { ...copyCurrentSlide, end: 1 };
      } else if (width <= 640) {
        copyCurrentSlide = { ...copyCurrentSlide, end: 0 };
      }
    }
    setcurrentSlide(copyCurrentSlide);
  };
  useEffect(() => {
    changeSlideLengthWithWidth();
  }, [width]);

  return (
    <div className="w-full relative grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 col-span-2 gap-10 my-10 overflow-x-hidden px-3 ">
      {products
        ?.filter((node: any, index: number) => {
          return index >= currentSlide?.start && index <= currentSlide?.end;
        })
        .map((node) => {
          return <MerchCard {...node} />;
        })}
      <button
        onClick={() => {
          changeSlide("backwards", currentSlide?.start);
        }}
        className={`rounded-[50%]  absolute rotate-180 left-0 top-[50%] -translate-y-[50%] shadow-md bg-[#616161] p-3 sm:p-4`}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 15.625L6.925 14.55L12.75 8.75H0.375V7.25H12.75L6.925 1.45L8 0.375L15.625 8L8 15.625Z"
            fill="white"
          ></path>
        </svg>
      </button>

      <button
        onClick={() => {
          changeSlide("forwards", currentSlide?.end);
        }}
        className={`rounded-[50%]  absolute right-0 top-[50%] -translate-y-[50%] shadow-md bg-[#616161] p-3 sm:p-4`}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 15.625L6.925 14.55L12.75 8.75H0.375V7.25H12.75L6.925 1.45L8 0.375L15.625 8L8 15.625Z"
            fill="white"
          ></path>
        </svg>
      </button>
    </div>
  );
}

export const MerchCard: React.FC<Product> = (product) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const region =
    Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone?.includes("Asia/Kolkata") ||
    Intl.DateTimeFormat().resolvedOptions().timeZone?.includes("Asia/Calcutta")
      ? "ind"
      : "usd";
  const navigateTo = (node: any) => {
    node = node.toLowerCase()?.replaceAll(" ", "_");
    console.log("nodeede", node);
    navigate(`/merchandize/${node}`);
    window.scrollTo({
      top: 0,
      left: 1,
      behavior: "smooth",
    });
  };

  const addTocart = () => {
    dispatch(
      Actions?.CartAction({
        price: region === "ind" ? product?.prices?.ind : product?.prices?.usd,
        quantity: 1,
        name: product?.title,
      })
    );
    toast?.success(`${product?.title} has been added to your cart.`);
  };

  console.log("kjaksjdnakjdsn", product?.image[0]?.url);
  return (
    <div
      onClick={() => {
        navigateTo(product.title);
      }}
      className="  cursor-pointer relative grid gap-5 items-start my-3 text-black-75 p-5 rounded-lg bg-[#FFFFFF] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
    >
      <div className="bg-stone-100 w-full rounded-md relative">
        <div
          style={{
            border: `1px solid  ${handleStockLabel(product?.stock)?.color}`,
            color: handleStockLabel(product?.stock)?.color,
          }}
          className={`text-xs  absolute p-1 border-4  right-0 bottom-0 rounded-[999px] -translate-x-1 font-light flex items-center justify-center`}
        >
          {handleStockLabel(product?.stock)?.text}
        </div>
        <img
          className=" h-[200px] hover:-translate-y-1 duration-100  object-scale-down  my-3 mx-auto"
          src={`${env}${product?.image[0]?.url}`}
          alt={product?.title}
        />
      </div>
      <div className="">
        <p className="text-lg font-medium">{product.title}</p>
        <p className="text-base mt-1 min-h-[48px]">{product.Cardtitle}</p>
      </div>
      <div className="flex justify-between w-full items-center mt-7">
        {region === "ind" ? (
          <div className="font-semibold lg:text-lg whitespace-nowrap md:text-base text-base ">
            <span className=" text-base  font-light">₹</span>
            {product.price?.split("#")[0]}
          </div>
        ) : (
          <div className="font-semibold lg:text-lg whitespace-nowrap md:text-base text-base ">
            <span className=" text-base  font-light">$</span>
            {product.price?.split("#")[1]}
          </div>
        )}
        <button
          onClick={(e) => {
            e.stopPropagation();
            addTocart();
          }}
          disabled={product?.stock === 0 ? true : false}
          className={`px-4 ${
            product?.stock === 0
              ? "  cursor-not-allowed  border-orange-300  text-orange-300 "
              : "border-orange-500  hover:bg-orange-500 hover:text-white text-orange-500 "
          } flex items-center gap-2 group hover:scale-[1.04] duration-75  rounded-full border py-2 font-medium `}
        >
          <CartIcon
            styles={
              product?.stock === 0
                ? "fill-orange-300"
                : "fill-orange-500 group-hover:fill-white `"
            }
          />{" "}
          Add to cart
        </button>
      </div>
    </div>
  );
};
