import axios from "axios";
import React, { useEffect } from "react";
import { env } from "../config";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../redux/actions";
import { useLocation } from "react-router";
import Loader from "../components/Loader";
import { MerchCard } from "./MerchandizeIndividualPage";
import Checkout from "../components/Checkout";
import PurchaseModal from "../components/PurchaseModal";

export const handleStockLabel = (stock: number) => {
  if (stock === 0) {
    return {
      text: "Out Of Stock",
      color: "#ef4444",
    };
  } else if (stock > 0 && stock <= 20) {
    return {
      text: "Only Few Left",
      color: "#f59e0b",
    };
  } else {
    return {
      text: "In Stock",
      color: "#22c55e",
    };
  }
};
export interface Product {
  title: string;
  color: string;
  dimension: string;
  material: any;
  pattern: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  Cardtitle: string;
  price: string;
  prices: {
    usd: string;
    ind: string;
  };
  stock: number;
  image: Attributes[];
  titleValue: TitleValue[];
  quantity?: number; // Added quantity directly to Product
}

export interface ProductWithQuantity {
  title: string;
  color: string;
  dimension: string;
  material: any;
  pattern: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  Cardtitle: string;
  price: string;
  prices: {
    usd: string;
    ind: string;
  };
  stock: number;
  image: Attributes[];
  titleValue: TitleValue[];
  quantity: number; // Added quantity directly to Product
}

export interface Attributes {
  name: string;
  alternativeText: any;
  caption: any;
  width: number;
  height: number;
  formats: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: any;
  provider: string;
  provider_metadata: any;
  createdAt: string;
  updatedAt: string;
}

export interface Formats {
  thumbnail: Thumbnail;
  small: Small;
  medium: Medium;
  large: Large;
}

export interface Thumbnail {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface Small {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface Medium {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface Large {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: any;
  width: number;
  height: number;
  size: number;
  url: string;
}

export interface TitleValue {
  id: number;
  title: string;
  value: string;
}

function Merchandize() {
  const dispatch = useDispatch();

  const ProductData: any = useSelector(
    (state: any) => state?.Page?.ProductData
  );

  const getProductData = () => {
    axios.get(`${env}/api/products?populate=deep`).then(function (response) {
      dispatch(Actions.ProductDataAction(response?.data?.data));
    });
  };

  useEffect(() => {
    if (ProductData.length === 0) {
      getProductData();
    }
    window.scrollTo({
      top: 0,
      left: 1,
      behavior: "smooth",
    });
  }, []);

  const location = useLocation();
  return ProductData?.length ? (
    <div className="bg-[#e9e9e9]">
      {location?.pathname?.includes("success") ? <PurchaseModal /> : null}
      <div className=" flex justify-center  text-2xl md:text-4xl lg:text-5xl max-w-screen-2xl mx-auto px-5 lg:px-10 xl:px-20 2xl:px- relative  py-7 md:pb-0  lg:pt-14 bg-[#e9e9e9] font-bold font-custom text-black-75 ">
        <Checkout />
        <p className="mx-auto">Merchandize</p>
      </div>
      <div className="px-5 py-7 lg:px-10 xl:px-20 lg:py-14 font-custom  grid place-content-center col-span-full bg-[#e9e9e9] text-black-75 ">
        <div className="max-w-screen-2xl grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7">
          {ProductData?.map((node: Product) => {
            console.log("attriburtesss", node);
            return <MerchCard {...node} />;
          })}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
}

export default Merchandize;

export const CartIcon = ({ styles }: { styles?: string }) => (
  <svg
    className={`${styles ?? ` fill-orange-500  `} w-5 h-5`}
    xmlns="http://www.w3.org/2000/svg"
    width="800"
    height="800"
    viewBox="0 0 16 16"
  >
    <path d="M13.35 10.48H4.5l-.24-1.25h9.13a1.24 1.24 0 0 0 1.22-1l.84-4a1.25 1.25 0 0 0-1.22-1.51H3l-.22-1.24H.5v1.25h1.25l1.5 7.84a2 2 0 0 0-1.54 1.93 2.09 2.09 0 0 0 2.16 2 2.08 2.08 0 0 0 2.13-2 2 2 0 0 0-.16-.77h5.49a2 2 0 0 0-.16.77 2.09 2.09 0 0 0 2.16 2 2 2 0 1 0 0-4zM14.23 4l-.84 4H4l-.74-4zM3.87 13.27A.85.85 0 0 1 3 12.5a.85.85 0 0 1 .91-.77.84.84 0 0 1 .9.77.84.84 0 0 1-.94.77m9.48 0a.85.85 0 0 1-.91-.77.92.92 0 0 1 1.81 0 .85.85 0 0 1-.9.77"></path>
  </svg>
);
